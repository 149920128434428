[
  {
    "name": "osmosis",
    "gasPrice": "0.0025uosmo",
    "ownerAddress": "osmovaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4d3kvts",
    "default": true,
    "maxPerDay": 1
  },
  {
    "name": "juno",
    "gasPrice": "0.0025ujuno",
    "ownerAddress": "junovaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4kjctkq"
  },
  {
    "name": "cosmoshub",
    "gasPrice": "0.0025uatom",
    "ownerAddress": "cosmosvaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q46fe2xk"
  },
  {
    "name": "akash",
    "ownerAddress": "akashvaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4cyw7r4"
  },
  {
    "name": "chihuahua",
    "ownerAddress": "chihuahuavaloper1pzqjgfd25qsyfdtmx9elrqx6zjjvnc9sj52r2y",
    "gasPrice": "1uhuahua",
    "gasPricePrefer": "1uhuahua"
  },
  {
    "name": "gravitybridge",
    "gasPrice": "0.025ugraviton",
    "ownerAddress": "gravityvaloper1h4w306x3tm39gas6evu326c9ycxwrvk4drnv5t"
  },
  {
    "name": "regen",
    "gasPrice": "0.03uregen",
    "ownerAddress": "regenvaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4ucmxys",
    "autostake": {
      "batchTxs": 5
    }
  },
  {
    "name": "terra",
    "gasPrice": "5.665uluna",
    "ownerAddress": "terravaloper12079m57cew2v02zs624zvyed5479an9wxh03fy"
  },
  {
    "name": "terra2",
    "gasPrice": "0.015uluna",
    "ownerAddress": "terravaloper1plxp55happ379eevsnk2xeuwzsrldsmqduyu36"
  },
  {
    "name": "sentinel",
    "gasPrice": "0.02udvpn"
  },
  {
    "name": "dig",
    "gasPrice": "0.01udig",
    "ownerAddress": "digvaloper136avwnuvvy94dqmtnaue2nfvjes8xr37h9rzay"
  },
  {
    "name": "bitcanna",
    "gasPrice": "0.001ubcna"
  },
  {
    "name": "emoney",
    "gasPrice": "0.08ungm"
  },
  {
    "name": "kava",
    "gasPrice": "0.00008ukava"
  },
  {
    "name": "desmos",
    "gasPrice": "0.001udsm"
  },
  {
    "name": "cryptoorgchain",
    "gasPrice": "0.025basecro",
    "ownerAddress": "crocncl10mfs428fyntu296dgh5fmhvdzrr2stlaekcrp9"
  },
  {
    "name": "evmos",
    "txTimeout": 120000,
    "ownerAddress": "evmosvaloper1rh9uz23gmhr6q92a45qrath4fr9ffefz4eas0z",
    "maxPerDay": 1
  },
  {
    "name": "sifchain",
    "gasPrice": "1500000000000rowan",
    "ownerAddress": "sifvaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4nz2r97"
  },
  {
    "name": "lumnetwork"
  },
  {
    "name": "stargaze",
    "ownerAddress": "starsvaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4qlwk6v"
  },
  {
    "name": "comdex",
    "ownerAddress": "comdexvaloper17f70yjkvmvld379904jaddx9h0f74n32pjtmp6"
  },
  {
    "name": "cheqd",
    "gasPrice": "25ncheq"
  },
  {
    "name": "umee",
    "ownerAddress": "umeevaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4d0h0la"
  },
  {
    "name": "bitsong"
  },
  {
    "name": "persistence"
  },
  {
    "name": "agoric",
    "ownerAddress": "agoricvaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4acufxj"
  },
  {
    "name": "impacthub",
    "gasPrice": "0.025uixo",
    "gasPricePrefer": "0.1uixo",
    "ownerAddress": "ixovaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q42frukn"
  },
  {
    "name": "kichain",
    "gasPrice": "0.025uxki"
  },
  {
    "name": "sommelier"
  },
  {
    "name": "konstellation",
    "image": "https://raw.githubusercontent.com/Konstellation/DARC_token/main/darctoken.svg"
  },
  {
    "name": "fetchhub",
    "gasPrice": "5000afet"
  },
  {
    "name": "cerberus",
    "gasPrice": "0.025ucrbrus",
    "autostake": {
      "batchTxs": 100
    },
    "ownerAddress": "cerberusvaloper1pzqjgfd25qsyfdtmx9elrqx6zjjvnc9stqd7h0"
  },
  {
    "name": "secretnetwork",
    "gasPrice": "0.025uscrt",
    "gasPricePrefer": "0.05uscrt",
    "authzAminoSupport": true
  },
  {
    "name": "bostrom",
    "gasPrice": "0boot"
  },
  {
    "name": "starname",
    "gasPrice": "10uvoi"
  },
  {
    "name": "rizon",
    "gasPrice": "0.0001uatolo",
    "ownerAddress": "rizonvaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q40yn0yd"
  },
  {
    "name": "decentr",
    "gasPrice": "0.025udec"
  },
  {
    "name": "assetmantle",
    "gasPrice": "0.025umntl",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    },
    "ownerAddress": "mantlevaloper1fqs7gakxdmujtk0qufdzth5pfyspus3yx394zd"
  },
  {
    "name": "crescent",
    "gasPrice": "0.025ucre",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    },
    "ownerAddress": "crevaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4ep74jz"
  },
  {
    "name": "meme",
    "gasPrice": "0.025umeme"
  },
  {
    "name": "cronos",
    "enabled": false
  },
  {
    "name": "harpoon",
    "ownerAddress": "kujiravaloper1vue5lawr3s0au9zj0aqeft5aknx6cjq6w5ghca",
    "testnet": true
  },
  {
    "name": "kujira",
    "ownerAddress": "kujiravaloper1vue5lawr3s0au9zj0aqeft5aknx6cjq6w5ghca"
  },
  {
    "name": "genesisl1",
    "txTimeout": 120000
  },
  {
    "name": "tgrade",
    "enabled": false
  },
  {
    "name": "aioz",
    "enabled": false
  },
  {
    "name": "echelon",
    "keplrFeatures": [
      "ibc-transfer",
      "ibc-go",
      "eth-address-gen",
      "eth-key-sign"
    ]
  },
  {
    "name": "kichaintestnet",
    "testnet": true
  },
  {
    "name": "likecoin",
    "gasPrice": "10nanolike"
  },
  {
    "name": "passage",
    "ownerAddress": "pasgvaloper196rujtjehu0dfc7y85lkcaps6tel76g3l9knjy"
  },
  {
    "name": "stride",
    "ownerAddress": "stridevaloper1x2kta40h5rnymtjn6ys7vk2d87xu7y6zfu9j3r",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    }
  },
  {
    "name": "pulsar",
    "testnet": true
  },
  {
    "name": "teritori",
    "ownerAddress": "torivaloper1n3mhyp9fvcmuu8l0q8qvjy07x0rql8q4cmefrc",
    "gasPriceStep": {
      "low": 0,
      "average": 0.025,
      "high": 0.04
    }
  }
]